import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './nav-bar.css'

const NavBar = (props) => {
  return (
    <div className={`nav-bar-container ${props.rootClassName} `}>
      <div className="nav-bar-container01">
        <div className="nav-bar-container02">
          <div className="nav-bar-navigation">
            <div className="nav-bar-container03">
              <a href="#work">
                <div className="nav-bar-container04">
                  <span className="nav-bar-text">
                    {props.text ?? (
                      <Fragment>
                        <span className="nav-bar-text12">Work</span>
                      </Fragment>
                    )}
                  </span>
                </div>
              </a>
              <a href="#capabilities">
                <div className="nav-bar-container05">
                  <span className="nav-bar-text01">
                    {props.text4 ?? (
                      <Fragment>
                        <span className="nav-bar-text14">Capabilities</span>
                      </Fragment>
                    )}
                  </span>
                </div>
              </a>
              <a href="#process">
                <div className="nav-bar-container06">
                  <span className="nav-bar-text02">
                    {props.text3 ?? (
                      <Fragment>
                        <span className="nav-bar-text13">Process</span>
                      </Fragment>
                    )}
                  </span>
                </div>
              </a>
            </div>
          </div>
          <div className="nav-bar-contactcta">
            <a
              href={props.buttonUrl}
              target="_blank"
              rel="noreferrer noopener"
              className="nav-bar-link03"
            >
              <div className="nav-bar-container07">
                <span className="nav-bar-text03">
                  {props.text5 ?? (
                    <Fragment>
                      <span className="nav-bar-text09">
                        <span className="nav-bar-text10">⬤</span>
                        <span>  Let&apos;s Talk!</span>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <header data-thq="thq-navbar" className="nav-bar-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="nav-bar-desktop-menu">
          <div className="nav-bar-logo">
            <a href="#home" className="nav-bar-link04">
              <img
                alt={props.imageAlt1}
                src="/blockboostlogo-200w.webp"
                className="nav-bar-image"
              />
            </a>
          </div>
          <div className="nav-bar-navigation1">
            <div className="nav-bar-container08 nav">
              <a href="#work">
                <div className="nav-bar-container09">
                  <span className="nav-bar-text04">
                    {props.text1 ?? (
                      <Fragment>
                        <span className="nav-bar-text21">Work</span>
                      </Fragment>
                    )}
                  </span>
                </div>
              </a>
              <a href="#capabilities">
                <div className="nav-bar-container10">
                  <span className="nav-bar-text05">
                    {props.text41 ?? (
                      <Fragment>
                        <span className="nav-bar-text22">Capabilities</span>
                      </Fragment>
                    )}
                  </span>
                </div>
              </a>
              <a href="#process">
                <div className="nav-bar-container11">
                  <span className="nav-bar-text06">
                    {props.text31 ?? (
                      <Fragment>
                        <span className="nav-bar-text23">Process</span>
                      </Fragment>
                    )}
                  </span>
                </div>
              </a>
            </div>
          </div>
          <div className="nav-bar-contactcta1">
            <a
              href={props.buttonUrl1}
              target="_blank"
              rel="noreferrer noopener"
              className="nav-bar-link08"
            >
              <div className="nav-bar-container12">
                <span className="nav-bar-text07">
                  {props.text51 ?? (
                    <Fragment>
                      <span className="nav-bar-text18">
                        <span className="nav-bar-text19">⬤</span>
                        <span>  Let&apos;s Talk!</span>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </a>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="nav-bar-burger-menu">
          <div className="nav-bar-logo1">
            <a href="#home" className="nav-bar-link09">
              <img
                alt={props.imageAlt11}
                src="/blockboostlogo-200w.webp"
                className="nav-bar-image1"
              />
            </a>
          </div>
          <svg viewBox="0 0 1024 1024" className="nav-bar-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="nav-bar-mobile-menu">
          <div className="nav-bar-nav">
            <div className="nav-bar-top">
              <a href="#home" className="nav-bar-link10">
                <img
                  alt={props.imageAlt}
                  src="/blockboostlogo-200w.webp"
                  className="nav-bar-image2"
                />
              </a>
              <div data-thq="thq-close-menu" className="nav-bar-close-menu">
                <svg viewBox="0 0 1024 1024" className="nav-bar-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="nav-bar-links">
              <a href="#work" className="nav-bar-work">
                {props.link11 ?? (
                  <Fragment>
                    <span className="nav-bar-text15 thq-link thq-body-small">
                      Work
                    </span>
                  </Fragment>
                )}
              </a>
              <a href="#capabilities" className="nav-bar-capabilities">
                {props.link21 ?? (
                  <Fragment>
                    <span className="nav-bar-text16 thq-link thq-body-small">
                      Capabilities
                    </span>
                  </Fragment>
                )}
              </a>
              <a href="#process" className="nav-bar-process">
                {props.link31 ?? (
                  <Fragment>
                    <span className="nav-bar-text17 thq-link thq-body-small">
                      Process
                    </span>
                  </Fragment>
                )}
              </a>
            </nav>
            <div className="nav-bar-buttons">
              <a
                href={props.buttonUrl11}
                target="_blank"
                rel="noreferrer noopener"
                className="nav-bar-link11"
              >
                <div className="nav-bar-container13">
                  <span className="nav-bar-text08">
                    {props.text511 ?? (
                      <Fragment>
                        <span className="nav-bar-text24">
                          <span className="nav-bar-text25">⬤</span>
                          <span>  Let&apos;s Talk!</span>
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
              </a>
            </div>
          </div>
          <div className="nav-bar-icon-group">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="thq-icon-x-small"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="thq-icon-x-small"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="thq-icon-x-small"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
    </div>
  )
}

NavBar.defaultProps = {
  text5: undefined,
  text: undefined,
  text3: undefined,
  buttonUrl: 'http://link.blockboost.org/discord',
  rootClassName: '',
  imageAlt: 'image',
  text4: undefined,
  link11: undefined,
  link21: undefined,
  link31: undefined,
  buttonUrl1: 'http://link.blockboost.org/discord',
  text51: undefined,
  text1: undefined,
  text41: undefined,
  text31: undefined,
  imageAlt1: 'image',
  buttonUrl11: 'http://link.blockboost.org/discord',
  text511: undefined,
  imageAlt11: 'image',
}

NavBar.propTypes = {
  text5: PropTypes.element,
  text: PropTypes.element,
  text3: PropTypes.element,
  buttonUrl: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt: PropTypes.string,
  text4: PropTypes.element,
  link11: PropTypes.element,
  link21: PropTypes.element,
  link31: PropTypes.element,
  buttonUrl1: PropTypes.string,
  text51: PropTypes.element,
  text1: PropTypes.element,
  text41: PropTypes.element,
  text31: PropTypes.element,
  imageAlt1: PropTypes.string,
  buttonUrl11: PropTypes.string,
  text511: PropTypes.element,
  imageAlt11: PropTypes.string,
}

export default NavBar
