import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import NavBar from '../components/nav-bar'
import Card from '../components/card'
import CTA from '../components/cta'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div id="home" className="home-container">
      <Helmet>
        <title>BlockBoost | Home</title>
        <meta
          name="description"
          content="We grow Minecraft servers with the electrifying power of TikTok."
        />
        <meta property="og:title" content="BlockBoost | Home" />
        <meta
          property="og:description"
          content="We grow Minecraft servers with the electrifying power of TikTok."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a4029d7a-40d3-4a39-af58-6dd0a88074e3/01aefefd-da79-4a35-a98a-3bc1e866594e?org_if_sml=1&amp;force_format=original"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="home-nav-container">
        <NavBar
          text={
            <Fragment>
              <span className="home-text">Work</span>
            </Fragment>
          }
          text3={
            <Fragment>
              <span className="home-text01">Procces</span>
            </Fragment>
          }
          text4={
            <Fragment>
              <span className="home-text02">Capabilities</span>
            </Fragment>
          }
          text5={
            <Fragment>
              <span className="home-text03">
                <span className="home-text04">⬤</span>
                <span>  Let&apos;s Talk!</span>
              </span>
            </Fragment>
          }
          rootClassName="nav-bar-root-class-name"
          link11={
            <Fragment>
              <span className="home-text06 thq-link thq-body-small">Work</span>
            </Fragment>
          }
          link21={
            <Fragment>
              <span className="home-text07 thq-link thq-body-small">
                Capabilities
              </span>
            </Fragment>
          }
          link31={
            <Fragment>
              <span className="home-text08 thq-link thq-body-small">
                Process
              </span>
            </Fragment>
          }
          text51={
            <Fragment>
              <span className="home-text09">
                <span className="home-text10">⬤</span>
                <span>  Let&apos;s Talk!</span>
              </span>
            </Fragment>
          }
          text1={
            <Fragment>
              <span className="home-text12">Work</span>
            </Fragment>
          }
          text41={
            <Fragment>
              <span className="home-text13">Capabilities</span>
            </Fragment>
          }
          text31={
            <Fragment>
              <span className="home-text14">Process</span>
            </Fragment>
          }
          text511={
            <Fragment>
              <span className="home-text15">
                <span className="home-text16">⬤</span>
                <span>  Let&apos;s Talk!</span>
              </span>
            </Fragment>
          }
        ></NavBar>
      </div>
      <div id="hero" className="home-hero">
        <div className="home-container01">
          <div className="home-container02">
            <div className="home-container03">
              <span className="home-text18">
                <span>
                  We grow Minecraft servers with the
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text20">electrifying power</span>
                <span> of TikTok.</span>
              </span>
              <span className="home-text22">
                Welcome to BlockBoost, where the magic of Minecraft meets the
                enchantment of TikTok, and discover a whole new dimension of
                server advertising.
              </span>
            </div>
            <div className="home-container04">
              <a
                href="https://link.blockboost.org/discord"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link"
              >
                <div className="home-container05">
                  <span className="home-text23">Talk to us</span>
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M512 170l342 342-342 342-60-60 238-240h-520v-84h520l-238-240z"></path>
                  </svg>
                </div>
              </a>
              <a href="#work" className="home-link1">
                <div className="home-container06">
                  <span className="home-text24">Our work</span>
                  <svg viewBox="0 0 1024 1024" className="home-icon2">
                    <path d="M854 512l-342 342-342-342 62-60 238 238v-520h84v520l240-238z"></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
          <div className="home-container07">
            <img
              sizes="(min-width: 768px) 800px, 480px"
              src="/bb_gif-600h.gif"
              alt="image"
              srcSet="/bb_gif-600h.gif 800w, /bb_gif-mobile.gif 480w"
              className="home-image"
            />
          </div>
        </div>
      </div>
      <div id="work" className="home-work">
        <div className="home-container08">
          <h1 className="home-text25">A collection of our past work</h1>
          <h2 className="home-text26">
            See some of our work below. For more work, feel free to check our
            Discord
          </h2>
        </div>
      </div>
      <div id="capabilities" className="home-capabilities">
        <div className="home-container09">
          <h1 className="home-text27">Why BlockBoost?</h1>
          <div className="home-container10">
            <div className="home-container11">
              <img
                alt="image"
                src="https://assets-global.website-files.com/6472b6f613be7a401607d4c6/647fa18305e13e2ade4e6d13_TikTok-icon-glyph.png"
                className="home-image1"
              />
              <span className="home-text28">We understand the algorithm</span>
              <span className="home-text29">
                Don&apos;t waste time trying to understand the TikTok algorithm.
                We&apos;ve already done the work for you. We&apos;ve grown
                dozens of accounts from zero, and learned numerous growth
                strategies along the way.
              </span>
            </div>
            <div className="home-container12">
              <img
                alt="image"
                src="https://assets-global.website-files.com/6472b6f613be7a401607d4c6/647fa33c05e8a2940a820bb0_GrassNew.webp"
                className="home-image2"
              />
              <span className="home-text30">We play the game</span>
              <span className="home-text31">
                Our team has a deep understanding of Minecraft, and it&apos;s
                target audience. This ensures your server is marketed to the
                right audience through ultra-engaging content.
              </span>
            </div>
            <div className="home-container13">
              <img
                alt="image"
                sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
                src="/blockboostlogo-1500h.webp"
                srcSet="/blockboostlogo-1500h.webp 1200w, /blockboostlogo-tablet.webp 800w, /blockboostlogo-mobile.webp 480w"
                className="home-image3"
              />
              <span className="home-text32">We create rapid growth</span>
              <span className="home-text33">
                Our track record demonstrates our ability to deliver results. By
                leveraging the power of TikTok, we can rapidly expand your
                server&apos;s reach, attracting new players and fostering an
                active community.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="process" className="home-proccess">
        <div className="home-proccess-title">
          <h1 className="home-text34">Our Process</h1>
          <span className="home-text35">
            Here&apos;s what you can expect when working with Blockboost
          </span>
        </div>
        <div className="home-container14">
          <Card
            step4Title={
              <Fragment>
                <h2 className="home-text36">Open a ticket with us</h2>
              </Fragment>
            }
            step4Title18={
              <Fragment>
                <h2 className="home-text37">Ideation</h2>
              </Fragment>
            }
            step4Title20={
              <Fragment>
                <h2 className="home-text38">Planning</h2>
              </Fragment>
            }
            rootClassName="card-root-class-name"
            step4Title201={
              <Fragment>
                <h2 className="home-text39">Invoice</h2>
              </Fragment>
            }
            step4Title2011={
              <Fragment>
                <h2 className="home-text40">Publishing</h2>
              </Fragment>
            }
            step4Description={
              <Fragment>
                <span className="home-text41">
                  We&apos;ll ask questions about your server and see if
                  we&apos;re a good fit
                </span>
              </Fragment>
            }
            step4Description18={
              <Fragment>
                <span className="home-text42">
                  We learn about your server&apos;s features and develop a
                  content strategy
                </span>
              </Fragment>
            }
            step4Description20={
              <Fragment>
                <span className="home-text43">
                  We&apos;ll plan out content to be released and draft a project
                  proposal
                </span>
              </Fragment>
            }
            step4Description201={
              <Fragment>
                <span className="home-text44">
                  We&apos;ll send a PayPal invoice outlining out agreement
                </span>
              </Fragment>
            }
            step4Description2011={
              <Fragment>
                <span className="home-text45">
                  Hooray! We&apos;re ready to strat. We&apos;ll either create
                  and grow a fresh TikTok fou you, or post on a pre-existing
                  account
                </span>
              </Fragment>
            }
          ></Card>
        </div>
      </div>
      <div className="home-cta">
        <CTA
          text={
            <Fragment>
              <span className="home-text46">Join our discord</span>
            </Fragment>
          }
          text1={
            <Fragment>
              <span className="home-text47">
                Connecting Minecraft Servers to the electrifing power of TikTok
              </span>
            </Fragment>
          }
          rootClassName="cta-root-class-name"
        ></CTA>
      </div>
      <div className="home-footer">
        <div className="home-container15">
          <Footer
            text2={
              <Fragment>
                <span className="home-text48">
                  We are not affiliated with Mojang, AB.
                </span>
              </Fragment>
            }
            rootClassName="footer-root-class-name"
          ></Footer>
        </div>
      </div>
    </div>
  )
}

export default Home
