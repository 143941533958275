import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-container ${props.rootClassName} `}>
      <div className="footer-container1">
        <span className="footer-text">
          {props.text2 ?? (
            <Fragment>
              <span className="footer-text1">
                We are not affiliated with Mojang, AB.
              </span>
            </Fragment>
          )}
        </span>
        <div className="footer-container2">
          <img
            alt={props.imageAlt1}
            src={props.imageSrc1}
            className="footer-image"
          />
        </div>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  text2: undefined,
  imageSrc1: '/discord.svg',
  imageAlt1: 'image',
  rootClassName: '',
}

Footer.propTypes = {
  text2: PropTypes.element,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Footer
