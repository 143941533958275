import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './cta.css'

const CTA = (props) => {
  return (
    <div className={`cta-container ${props.rootClassName} `}>
      <div className="cta-container1">
        <div className="cta-container2">
          <span>
            {props.text1 ?? (
              <Fragment>
                <span className="cta-text3">
                  Connecting Minecraft Servers to the electrifing power of
                  TikTok
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="cta-container3">
          <a
            href={props.containerUrl}
            target="_blank"
            rel="noreferrer noopener"
            className="cta-link"
          >
            <div className="cta-container4">
              <span className="cta-text1">
                {props.text ?? (
                  <Fragment>
                    <span className="cta-text2">Join our discord</span>
                  </Fragment>
                )}
              </span>
              <svg viewBox="0 0 1024 1024" className="cta-icon">
                <path d="M512 170l342 342-342 342-60-60 238-240h-520v-84h520l-238-240z"></path>
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

CTA.defaultProps = {
  containerUrl: 'https://link.blockboost.org/discord',
  text: undefined,
  rootClassName: '',
  text1: undefined,
}

CTA.propTypes = {
  containerUrl: PropTypes.string,
  text: PropTypes.element,
  rootClassName: PropTypes.string,
  text1: PropTypes.element,
}

export default CTA
