import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './card.css'

const Card = (props) => {
  return (
    <div className={`card-container ${props.rootClassName} `}>
      <div className="card-card-1 thq-card">
        <div className="card-container1">
          <img
            alt={props.step4ImageAlt}
            src="https://assets-global.website-files.com/6472b6f613be7a401607d4c6/6472b6f713be7a401607d540_048579A7-9748-4357-A675-BF76610495E7.webp"
            className="card-image"
          />
        </div>
        <p className="card-text">01</p>
        <h2 className="card-text01 Content2">
          {props.step4Title ?? (
            <Fragment>
              <h2 className="card-text23">Open a ticket with us</h2>
            </Fragment>
          )}
        </h2>
        <span className="card-text02 text">
          {props.step4Description ?? (
            <Fragment>
              <span className="card-text20">
                We&apos;ll ask questions about your server and see if we&apos;re
                a good fit
              </span>
            </Fragment>
          )}
        </span>
      </div>
      <div className="card-card-2 thq-card">
        <div className="card-container2">
          <img
            alt={props.step4ImageAlt18}
            src="https://assets-global.website-files.com/6472b6f613be7a401607d4c6/6472b6f713be7a401607d53e_EFB6AC06-B22C-42F1-B402-CF24DEF2A34D.webp"
            className="card-image1"
          />
        </div>
        <p className="card-text03">02</p>
        <h2 className="card-text04 Content2">
          {props.step4Title18 ?? (
            <Fragment>
              <h2 className="card-text21">Ideation</h2>
            </Fragment>
          )}
        </h2>
        <span className="card-text05 text">
          {props.step4Description18 ?? (
            <Fragment>
              <span className="card-text22">
                We learn about your server&apos;s features and develop a content
                strategy
              </span>
            </Fragment>
          )}
        </span>
      </div>
      <div className="card-card-3 thq-card">
        <div className="card-container3">
          <img
            alt={props.step4ImageAlt20}
            src="https://assets-global.website-files.com/6472b6f613be7a401607d4c6/6472b6f713be7a401607d53f_D579D1FF-97FE-42E0-803A-33634CB722AD.webp"
            className="card-image2"
          />
        </div>
        <p className="card-text06">03</p>
        <h2 className="card-text07 Content2">
          {props.step4Title20 ?? (
            <Fragment>
              <h2 className="card-text15">Planning</h2>
            </Fragment>
          )}
        </h2>
        <span className="card-text08 text">
          {props.step4Description20 ?? (
            <Fragment>
              <span className="card-text19">
                We&apos;ll plan out content to be released and draft a project
                proposal
              </span>
            </Fragment>
          )}
        </span>
      </div>
      <div className="card-card-4 thq-card">
        <div className="card-container4">
          <img
            alt={props.step4ImageAlt201}
            src="https://assets-global.website-files.com/6472b6f613be7a401607d4c6/6472b6f713be7a401607d53d_0C17496A-94A8-4C96-BA7A-447EE7E40FD4.webp"
            className="card-image3"
          />
        </div>
        <p className="card-text09">04</p>
        <h2 className="card-text10 Content2">
          {props.step4Title201 ?? (
            <Fragment>
              <h2 className="card-text24">Invoice</h2>
            </Fragment>
          )}
        </h2>
        <span className="card-text11 text">
          {props.step4Description201 ?? (
            <Fragment>
              <span className="card-text16">
                We&apos;ll send a PayPal invoice outlining out agreement
              </span>
            </Fragment>
          )}
        </span>
      </div>
      <div className="card-card-5 thq-card">
        <div className="card-container5">
          <img
            alt={props.step4ImageAlt2011}
            src="https://assets-global.website-files.com/6472b6f613be7a401607d4c6/6472b6f713be7a401607d541_02073CC0-207B-4079-9DA5-BBFDA837CE50.webp"
            className="card-image4"
          />
        </div>
        <p className="card-text12">05</p>
        <h2 className="card-text13 Content2">
          {props.step4Title2011 ?? (
            <Fragment>
              <h2 className="card-text17">Publishing</h2>
            </Fragment>
          )}
        </h2>
        <span className="card-text14 text">
          {props.step4Description2011 ?? (
            <Fragment>
              <span className="card-text18">
                Hooray! We&apos;re ready to strat. We&apos;ll either create and
                grow a fresh TikTok fou you, or post on a pre-existing account
              </span>
            </Fragment>
          )}
        </span>
      </div>
    </div>
  )
}

Card.defaultProps = {
  step4ImageAlt: 'Growing server community',
  step4Title20: undefined,
  step4Description201: undefined,
  step4Title2011: undefined,
  step4ImageAlt201: 'Growing server community',
  step4ImageAlt2011: 'Growing server community',
  rootClassName: '',
  step4ImageAlt20: 'Growing server community',
  step4Description2011: undefined,
  step4Description20: undefined,
  step4ImageAlt18: 'Growing server community',
  step4Description: undefined,
  step4Title18: undefined,
  step4Description18: undefined,
  step4Title: undefined,
  step4Title201: undefined,
}

Card.propTypes = {
  step4ImageAlt: PropTypes.string,
  step4Title20: PropTypes.element,
  step4Description201: PropTypes.element,
  step4Title2011: PropTypes.element,
  step4ImageAlt201: PropTypes.string,
  step4ImageAlt2011: PropTypes.string,
  rootClassName: PropTypes.string,
  step4ImageAlt20: PropTypes.string,
  step4Description2011: PropTypes.element,
  step4Description20: PropTypes.element,
  step4ImageAlt18: PropTypes.string,
  step4Description: PropTypes.element,
  step4Title18: PropTypes.element,
  step4Description18: PropTypes.element,
  step4Title: PropTypes.element,
  step4Title201: PropTypes.element,
}

export default Card
